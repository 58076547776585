
import { defineComponent } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { useI18n } from "vue-i18n";

interface Exercise {
  createdAt: string;
  subject: { _id: string; name: string };
}

interface Course {
  createdAt: string;
  subject: { _id: string; name: string };
}

export default defineComponent({
  name: "Student-Attendance-Statistic",
  props: {
    title: String,
    description: String,
    courses: Array,
    exercises: Array,
    height: Number,
  },
  components: {},
  setup(props) {
    const { t } = useI18n();

    const exercises = props.exercises as Exercise[];
    const courses = props.courses as Course[];
    const subjects: string[] = [];
    const exercisesData: number[] = [];
    const coursesData: number[] = [];

    // get the list of subject names
    [...exercises, ...courses].forEach((el) => {
      if (subjects.indexOf(el.subject.name) == -1)
        subjects.push(el.subject.name);
    });

    // get the courses/exercises data
    for (const subject of subjects) {
      exercisesData.push(
        exercises.filter((exercise) => exercise.subject.name == subject).length
      );

      coursesData.push(
        courses.filter((course) => course.subject.name == subject).length
      );
    }

    const maxY = Math.max(...exercisesData, ...coursesData);

    const primaryColor = getCSSVariableValue("--bs-primary");
    const warningColor = getCSSVariableValue("--bs-warning");

    const colors = [primaryColor, warningColor];

    const series = [
      {
        name: t("course.courses"),
        data: coursesData,
      },
      {
        name: t("course.exercises"),
        data: exercisesData,
      },
    ];

    const options = {
      series,
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        sparkline: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      colors,
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: subjects,
      },
      yaxis: {
        min: 0,
        max: maxY > 5 ? maxY + 1 : 4,
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
    };
    return {
      options,
      series,
    };
  },
});
