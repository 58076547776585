
import { defineComponent } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { useI18n } from "vue-i18n";
import moment from "moment";

interface Exercise {
  createdAt: string;
  subject: { _id: string; name: string };
}

interface Course {
  createdAt: string;
  subject: { _id: string; name: string };
}

export default defineComponent({
  name: "Student-Attendance-Statistic",
  props: {
    title: String,
    description: String,
    courses: Array,
    exercises: Array,
    height: Number,
  },
  components: {},
  setup(props) {
    const { t } = useI18n();

    const exercises = props.exercises as Exercise[];
    const courses = props.courses as Course[];
    const monthNames: string[] = [];
    const exercisesData: number[] = [];
    const coursesData: number[] = [];

    let firstDate = new Date().getTime();
    const currentDate = new Date().getTime();

    // get the date of the first course or exercise created
    for (const data of [...courses, ...exercises]) {
      const currentDate = new Date(data.createdAt).getTime();
      if (currentDate < firstDate) firstDate = currentDate;
    }

    // get the number of months since the first month to now
    const numberMonths = new Date(currentDate - firstDate).getMonth() + 1;

    // get the list of month names and the courses/exercises data
    for (let i = 0; i <= numberMonths; i++) {
      const monthName = moment(firstDate).add(i, "M").format("MMMM");

      monthNames.push(monthName);

      exercisesData.push(
        exercises.filter(
          (exercise) => moment(exercise.createdAt).format("MMMM") == monthName
        ).length
      );

      coursesData.push(
        courses.filter(
          (course) => moment(course.createdAt).format("MMMM") == monthName
        ).length
      );
    }

    const primaryColor = getCSSVariableValue("--bs-primary");
    const warningColor = getCSSVariableValue("--bs-warning");

    const colors = [primaryColor, warningColor];

    const series = [
      {
        name: t("course.courses"),
        data: coursesData,
      },
      {
        name: t("course.exercises"),
        data: exercisesData,
      },
    ];

    const options = {
      series,
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        sparkline: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      colors,
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: monthNames,
      },
      yaxis: {
        min: 0,
        max: Math.max(...exercisesData, ...coursesData) + 1,
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
    };
    return {
      options,
      series,
    };
  },
});
