
import ApiService from "@/core/services/ApiService";
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import JsPDF from "jspdf";
import autoTable, { RowInput } from "jspdf-autotable";
import { event } from "vue-gtag";

interface CellType {
  content: string;
  rowSpan: number;
  styles: { halign: string; valign: string };
}

export default defineComponent({
  name: "timetable-accueil",
  components: {},
  props: { teacherID: String },
  setup(props) {
    const { t } = useI18n();
    const activeDay = "wednesday";
    const daysList = ["monday", "tuesday", "wednesday", "thursday", "friday"];

    const store = useStore();
    const user = store.getters.currentUser;
    const timetable = ref({});

    // eslint-disable-next-line
    const schedulesData = ref<any[]>([]);

    const teacherID = props.teacherID || user._id;

    daysList.forEach((day) => {
      timetable.value[day] = [];
    });

    ApiService.post("/schedules/filter", {
      query: {
        teacher: teacherID,
        status: { $ne: "inactive" },
        hidden: { $ne: true },
      },
      aggregation: [
        {
          $lookup: {
            from: "teachers",
            localField: "teacher",
            foreignField: "_id",
            as: "teacher",
          },
        },
        {
          $lookup: {
            from: "subjects",
            localField: "subject",
            foreignField: "_id",
            as: "subject",
          },
        },
        {
          $lookup: {
            from: "modules",
            localField: "module",
            foreignField: "_id",
            as: "module",
          },
        },
        {
          $lookup: {
            from: "modulespecifics",
            localField: "moduleSpecific",
            foreignField: "_id",
            as: "moduleSpecific",
          },
        },
        {
          $lookup: {
            from: "rooms",
            localField: "room",
            foreignField: "_id",
            as: "room",
          },
        },
        {
          $lookup: {
            from: "classrooms",
            localField: "classeRoom",
            foreignField: "_id",
            as: "classeRoom",
          },
        },
        {
          $project: {
            _id: 1,
            teacher: {
              $let: {
                vars: {
                  teacherVar: {
                    $arrayElemAt: ["$teacher", 0],
                  },
                },
                in: {
                  _id: "$$teacherVar._id",
                  employee: "$$teacherVar.employee",
                },
              },
            },
            classeRoom: {
              $let: {
                vars: {
                  classVar: {
                    $arrayElemAt: ["$classeRoom", 0],
                  },
                },
                in: {
                  name: "$$classVar.name",
                  _id: "$$classVar._id",
                },
              },
            },
            time: 1,
            day: 1,
            room: {
              $let: {
                vars: {
                  roomVar: {
                    $arrayElemAt: ["$room", 0],
                  },
                },
                in: {
                  name: "$$roomVar.name",
                  _id: "$$roomVar._id",
                },
              },
            },
            subject: {
              $let: {
                vars: {
                  subjectVar: {
                    $arrayElemAt: ["$subject", 0],
                  },
                },
                in: {
                  name: "$$subjectVar.name",
                  _id: "$$subjectVar._id",
                  status: "$$subjectVar.status",
                },
              },
            },
            module: {
              $let: {
                vars: {
                  moduleVar: {
                    $arrayElemAt: ["$module", 0],
                  },
                },
                in: {
                  name: "$$moduleVar.name",
                  _id: "$$moduleVar._id",
                  status: "$$moduleVar.status",
                },
              },
            },
            moduleSpecific: {
              $let: {
                vars: {
                  moduleSpecificVar: {
                    $arrayElemAt: ["$moduleSpecific", 0],
                  },
                },
                in: {
                  name: "$$moduleSpecificVar.name",
                  _id: "$$moduleSpecificVar._id",
                  status: "$$moduleSpecificVar.status",
                },
              },
            },
            ends: 1,
            duration: 1,
            type: 1,
            name: 1,
          },
        },
      ],
    })
      .then(({ data }) => {
        data = data
          .map((d) => {
            if (!d.type) d.type = "S";
            return d;
          })
          .filter(
            (d) =>
              (d.type != "S" || d.subject.status !== "inactive") &&
              (d.type != "MP" || d.module.status !== "inactive") &&
              (d.type != "MS" || d.moduleSpecific.status !== "inactive")
          );
        schedulesData.value = data;
        data.forEach((l) => {
          if (l.day < 5) {
            const schedName = l.name
              ? l.name
              : l.type == "MP"
              ? l.module.name
              : l.type == "MS"
              ? l.moduleSpecific.name
              : l.subject.name;
            timetable.value[daysList[l.day]].push({
              lesson: 0,
              subject: schedName,
              time: `${l.time}-${l.ends}`,
              cabinet: l.room ? l.room.name : null,
              classRoomName: l.classeRoom.name,
              start: l.time,
              end: l.ends,
            });
          }
        });

        daysList.forEach((day) => {
          // sort times
          timetable.value[day] = timetable.value[day].sort(function (a, b) {
            return (
              Number(a.start.split(":")[0]) - Number(b.start.split(":")[0])
            );
          });

          // eslint-disable-next-line
          let lastEnd: any = undefined;
          for (const l of timetable.value[day]) {
            if (lastEnd) {
              if (lastEnd.end != l.start) {
                lastEnd.break = `${lastEnd.end}-${l.start}`;
              }
            }
            lastEnd = l;
          }
        });
      })
      .catch((e) => console.log(e));

    const generatePDF = () => {
      if (props.teacherID)
        event("Imp teacher timetable", {
          event_category: "Impression PDF",
          event_label: "Advisor profile teacher",
          value: 1,
        });
      else
        event("Imp teacher timetable", {
          event_category: "Impression PDF",
          event_label: "Teacher dashborad",
          value: 1,
        });

      const imgData = new Image();
      imgData.src = store.getters.serverConfigUrl.building.logo;

      const doc = new JsPDF("landscape");

      const pdfWidth = doc.internal.pageSize.getWidth();

      doc.setFont("Amiri");
      doc.addImage(imgData, "JPEG", 8, 5, 30, 30);

      doc.setFontSize(15);
      const today = new Date();
      const options: Intl.DateTimeFormatOptions = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      const teacherName =
        schedulesData.value[0].teacher.employee.firstName +
        " " +
        schedulesData.value[0].teacher.employee.lastName;

      doc.setFontSize(10);

      doc.text(
        "Imprimé le : " + " " + today.toLocaleDateString("fr-FR", options),
        230,
        10
      );

      doc.setFontSize(18);
      doc.setFont("Courier");
      const width = doc.getTextWidth(`Emplois d'${teacherName}`);
      doc.text(`Emplois d'${teacherName}`, pdfWidth / 2 - width / 2, 30);

      const times = [
        "8:00",
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
      ];

      // eslint-disable-next-line
      const array: Array<Array<CellType | any>> = [];

      const rowsCount = 40;
      const cellToDelete = {};
      let position = 0;

      for (let i = 0; i < rowsCount; i++) {
        array[i] = new Array(7).fill(null);
      }

      for (let i = 0; i < times.length - 1; i++) {
        array[i * 4][0] = {
          content: times[i] + " - " + times[i + 1],
          rowSpan: 4,
          styles: { halign: "center", valign: "middle" },
        };
        for (let t = 1; t < 4; t++) array[i * 4 + t][0] = cellToDelete;
      }

      for (let i = 0; i < times.length - 1; i++) {
        // H:00
        let schedules = schedulesData.value.filter((el) => el.time == times[i]);

        schedules.forEach((sched) => {
          const day = Number(sched.day) + 1;
          const schedName = sched.name
            ? sched.name
            : sched.type == "MP"
            ? sched.module.name
            : sched.type == "MS"
            ? sched.moduleSpecific.name
            : sched.subject.name;
          const cellValue = schedName + "\r\n" + sched.classeRoom.name; //+ "\r\n" + sched.room.name;

          array[position][day] = {
            content: cellValue,
            rowSpan: sched.duration,
            styles: { halign: "center", valign: "middle" },
          };

          for (let t = 1; t < sched.duration; t++)
            array[position + t][day] = cellToDelete;
        });
        position++;

        // H:15
        schedules = schedulesData.value.filter(
          (el) => el.time == [times[i].split(":")[0], "15"].join(":")
        );

        schedules.forEach((sched) => {
          const day = Number(sched.day) + 1;
          const schedName = sched.name
            ? sched.name
            : sched.type == "MP"
            ? sched.module.name
            : sched.type == "MS"
            ? sched.moduleSpecific.name
            : sched.subject.name;
          const cellValue = schedName + "\r\n" + sched.classeRoom.name; //+ "\r\n" + sched.room.name;
          array[position][day] = {
            content: cellValue,
            rowSpan: sched.duration,
            styles: { halign: "center", valign: "middle" },
          };

          for (let t = 1; t < sched.duration; t++)
            array[position + t][day] = cellToDelete;
        });
        position++;

        // H:30
        schedules = schedulesData.value.filter(
          (el) => el.time == [times[i].split(":")[0], "30"].join(":")
        );

        schedules.forEach((sched) => {
          const day = Number(sched.day) + 1;
          const schedName = sched.name
            ? sched.name
            : sched.type == "MP"
            ? sched.module.name
            : sched.type == "MS"
            ? sched.moduleSpecific.name
            : sched.subject.name;
          const cellValue = schedName + "\r\n" + sched.classeRoom.name; //+ "\r\n" + sched.room.name;
          array[position][day] = {
            content: cellValue,
            rowSpan: sched.duration,
            styles: { halign: "center", valign: "middle" },
          };
          for (let t = 1; t < sched.duration; t++)
            array[position + t][day] = cellToDelete;
        });
        position++;

        // H:45
        schedules = schedulesData.value.filter(
          (el) => el.time == [times[i].split(":")[0], "45"].join(":")
        );

        schedules.forEach((sched) => {
          const day = Number(sched.day) + 1;
          const schedName = sched.name
            ? sched.name
            : sched.type == "MP"
            ? sched.module.name
            : sched.type == "MS"
            ? sched.moduleSpecific.name
            : sched.subject.name;
          const cellValue = schedName + "\r\n" + sched.classeRoom.name; //+ "\r\n" + sched.room.name;
          array[position][day] = {
            content: cellValue,
            rowSpan: sched.duration,
            styles: { halign: "center", valign: "middle" },
          };

          for (let t = 1; t < sched.duration; t++)
            array[position + t][day] = cellToDelete;
        });
        position++;
      }

      // fix table
      for (let row = 0; row < rowsCount; row++) {
        array[row] = array[row].filter((cell) => cell !== cellToDelete);
      }

      autoTable(doc, {
        columns: [
          "Temps",
          "Lundi",
          "Mardi",
          "Mercredi",
          "Jeudi",
          "Vendredi",
          "Samedi",
        ],
        body: array as RowInput[],

        theme: "grid",
        margin: { top: 40 },
        styles: { font: "Amiri", fontSize: 9 },
        headStyles: {
          valign: "middle",
          halign: "center",
          fontSize: 10,
          fontStyle: "bold",
        },
        willDrawCell: (data) => {
          if (data.section != "head") {
            data.row.height = 3.1;
            data.cell.contentHeight = 3.1;
            data.cell.height = 3.1 * data.cell.rowSpan;
          }
        },
        didParseCell: function (data) {
          if (data.column.index === 0) {
            data.cell.styles.fontStyle = "bold";
          }
        },
      });

      doc.save("Emplois d'" + teacherName + ".pdf");
    };

    return { t, timetable, activeDay, generatePDF, schedulesData };
  },
});
